import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/tabs/Tabs.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/tabs" storybook="https://workday.github.io/canvas-kit/?path=/story/components-containers-tabs--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8484&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/54aaf4a44443c2b68d241a25c5a0725c/536c7/tabs-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "28.10810810810811%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Card with annotation markers.",
              "title": "Image of a Card with annotation markers.",
              "src": "/static/54aaf4a44443c2b68d241a25c5a0725c/50383/tabs-anatomy.png",
              "srcSet": ["/static/54aaf4a44443c2b68d241a25c5a0725c/1efb2/tabs-anatomy.png 370w", "/static/54aaf4a44443c2b68d241a25c5a0725c/50383/tabs-anatomy.png 740w", "/static/54aaf4a44443c2b68d241a25c5a0725c/536c7/tabs-anatomy.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Active Tab`}</strong>{`: The tab that is selected and currently displaying it's contents.`}</li>
        <li parentName="ol"><strong parentName="li">{`Inactive Tab`}</strong>{`: All of the other unselected, available tabs.`}</li>
        <li parentName="ol"><strong parentName="li">{`Active Chip`}</strong>{`: Highlights the active tab.`}</li>
        <li parentName="ol"><strong parentName="li">{`Divider Line`}</strong>{`: Spans the width of the entire tabs container separating the tab set from its
contents.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Tabs are used to help the user navigate through information while remaining in context of the
page. This is the most important reason to use Tabs. Users should not navigate to a different page
when interacting with Tabs.`}</li>
        <li parentName="ul">{`Content that is grouped within a Tab should always be related so that the user knows what to
expect when they navigate each Tab. Never force users to switch back and forth between Tabs to
complete a task.`}</li>
        <li parentName="ul">{`Tab labels should be concise, scannable, and descriptive of their contents. Avoid using multiple
words for Tab labels. Never use icons. To reduce cognitive load and a cluttered UI, avoid using
more than 6 Tabs.`}</li>
        <li parentName="ul">{`Tabs should directly relate to the page section they’re within.`}</li>
        <li parentName="ul">{`The Tab divider line should span the full width of its container and create a clear distinction
between what’s under the Tab sections and the rest of the page.`}</li>
        <li parentName="ul">{`Do not use Tabs as a primary form of navigation.`}</li>
        <li parentName="ul">{`When there are too many tabs to display at once, use the overflow menu to indicate that more Tabs
are available.`}</li>
        <li parentName="ul">{`The recommended minimum width of a tab is 88px, standard padding to the left and right is 24px,
and minimum padding is 16px.`}</li>
        <li parentName="ul">{`You can place most components within Tabs, because they operate like mini-pages. For example,
Grids, Field Sets, and Prompts can all be added within a tabbed page.`}</li>
        <li parentName="ul">{`You can use fixed Tabs, especially when the contents of a tab is scrollable.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When To Use`}</h3>
      <ul>
        <li parentName="ul">{`When content can be grouped into logical sections in order to avoid overwhelming the user with a
lot of information. Tabs should cater for distinct groups of information.`}</li>
        <li parentName="ul">{`To alternate between two or more sections of organized content while keeping the user in-context
of the greater UI.`}</li>
        <li parentName="ul">{`Use Tabs to help section off and organize related content.`}</li>
        <li parentName="ul">{`When you have an abundance of related content to house in a small container.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When To Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider an alternative solution if the information within multiple Tabs needs to be viewed
simultaneously. Tabs are not suitable for comparing information or data, as it forces the user to
rely on short term memory when switching back and forth between Tabs.`}</li>
        <li parentName="ul">{`Consider using a more prominent, alternative form of navigation when there is a need for more than
6 or 7 Tabs.`}</li>
        <li parentName="ul">{`Avoid using Tabs within a card UI.`}</li>
        <li parentName="ul">{`If the content on a page is cohesive and doesn’t take up too much space, Tabs are likely
unnecessary.`}</li>
        <li parentName="ul">{`When Tabs are already being used on the page.`}</li>
      </ul>
      <h3 {...{
        "id": "variations"
      }}>{`Variations`}</h3>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Purpose`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default/Standard`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Should be used in most use cases for tabs.`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Full-Width`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Exclusively used in smaller containers ~300-400px width (e.g. within side panels or responsive UI).`}<ul><li>{`No margin between Tabs. Tab widths are evenly proportioned, defined by its container.`}</li><li>{`Active chip spans full-width of the active Tab.`}</li><li>{`2-3 Tabs max for this variation.`}</li><li>{`Do not use on large desktop experiences. More suitable for container widths less than 400px.`}</li></ul></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Wrapped Tabs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Edge case for when there is a requirement to have lengthy or multiple words for a Tab label.`}<ul><li>{`Max of two lines wrapped.`}</li><li>{`This variation increases the overall Tab set height.`}</li></ul></td>
          </tr>
        </tbody>
      </table>
      <h3 {...{
        "id": "touch-based-behavior"
      }}>{`Touch Based Behavior`}</h3>
      <p>{`Touch Based behavior for Tab compnents on web adapt and mimic the mobile experience to allow
overflow when the tab text stretches past the width of the users’ screen. These Tabs support mobile
behavior based on modality and help support WebView users. Touch Based behavior for Tabs will switch
from displaying an overflow menu to become scrollable from left to right in order to replicate a
mobile experience when viewing a WebView screen (not a native mobile screen) on a mobile device.`}</p>
      <h3 {...{
        "id": "states-and-behavior"
      }}>{`States and Behavior`}</h3>
      <ul>
        <li parentName="ul">{`By default, Tabs should always contain one Tab with an active state.`}</li>
        <li parentName="ul">{`Only one Tab can have an active state at a time.`}</li>
        <li parentName="ul">{`The inactive state of a Tab can inherit a hover, focus, and selected state.`}</li>
        <li parentName="ul">{`The active state of a Tab can only inherit a focus state.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each tab must have a focus indicator that is highly visible against the background and against the
non-focused state. Refer to `}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more
information.`}</p>
      <p>{`Tabs must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the active tab element`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Left Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Right Arrow`}</inlineCode>{`: move focus to the previous or next tab element`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` and `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate the focused tab element`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#alternative-tab-stop"
          }}>{`Alternative Tab Stop`}</a>{` : `}<inlineCode parentName="li">{`tabpanel`}</inlineCode>{` can optionally be included in focus
order to improve discoverability for screen readers, and allow scrolling with the keyboard when
the height is fixed.`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Tabs must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The focus is placed on a tab control`}</li>
        <li parentName="ul">{`Which page tab in the set is currently selected`}</li>
        <li parentName="ul">{`How many total number of tabs are available`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No design annotations needed.`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Interactive elements are not allowed inside of a `}<inlineCode parentName="li">{`tablist`}</inlineCode>{` or `}<inlineCode parentName="li">{`tab`}</inlineCode>{` element.`}</li>
        <li parentName="ul">{`[Included in component]`}{` All tab buttons must have a `}<inlineCode parentName="li">{`role="tab"`}</inlineCode>{`, inside of a parent element with
`}<inlineCode parentName="li">{`role="tablist"`}</inlineCode>{` attribute.`}</li>
        <li parentName="ul">{`[Included in component]`}{` All tabs are required to have an `}<inlineCode parentName="li">{`aria-selected="false"`}</inlineCode>{` attribute, except
for the actively selected tab, must be set to `}<inlineCode parentName="li">{`“true”`}</inlineCode>{`.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The content area container for the `}<inlineCode parentName="li">{`tablist`}</inlineCode>{`, must have a
`}<inlineCode parentName="li">{`role="tabpanel"`}</inlineCode>{` attribute and an `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{` attribute referencing the unique `}<inlineCode parentName="li">{`id`}</inlineCode>{` of the
active tab element.`}</li>
      </ul>
      <h3 {...{
        "id": "resources"
      }}>{`Resources`}</h3>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabpanel/"
          }}>{`Tabs | APG | WAI | W3C`}</a></li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When writing tab labels, be concise and avoid using multiple words when possible. Labels should
clearly describe the contents of the tab to the user.`}</li>
        <li parentName="ul">{`The page or page section title (if applicable) should make clear sense when placed after the tab
name. Try to imagine the page title is a noun after the tab label. For example: If the page is
titled “Fruit”, and the tabs are labeled “ripe” and “rotten”. You could read it in your head as
“ripe fruit” or “rotten fruit”.`}</li>
        <li parentName="ul">{`Refer to the `}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/tabs"
          }}>{`UI Text > Tabs`}</a>{` section of the Content Style Guide when
writing tab labels.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7739%3A7687&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c7308f4c7990306f34ee9332bc1b39f2/536c7/component-anatomy-tabs-mobile-ios.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Tab component.",
                "title": "The basic elements of the Tab component.",
                "src": "/static/c7308f4c7990306f34ee9332bc1b39f2/50383/component-anatomy-tabs-mobile-ios.png",
                "srcSet": ["/static/c7308f4c7990306f34ee9332bc1b39f2/1efb2/component-anatomy-tabs-mobile-ios.png 370w", "/static/c7308f4c7990306f34ee9332bc1b39f2/50383/component-anatomy-tabs-mobile-ios.png 740w", "/static/c7308f4c7990306f34ee9332bc1b39f2/536c7/component-anatomy-tabs-mobile-ios.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Tab Container`}</strong>{`: Contains all tab items.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item (Active)`}</strong>{`: Tab item whose content is currently displayed.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item (Inactive)`}</strong>{`: All other unselected, available tabs.`}</li>
          <li parentName="ol"><strong parentName="li">{`Active Chip`}</strong>{`: Highlights the active tab.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item Label`}</strong>{`: Describes the content contained.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item Icon (Optional)`}</strong>{`: Leading icon used to reinforce association with the label.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Panel`}</strong>{`: The content of the tab item.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider (Optional)`}</strong>{`: Spans the tab container to separate from its content.`}</li>
        </ol>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "fixed-width"
        }}>{`Fixed Width`}</h3>
        <p>{`All tab items have evenly distributed widths in the tab container. These tabs are not scrollable.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3d174d55dfaaf14124a9fbefb2dd1f65/536c7/component-variation-fixed-tabs-mobile-ios.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of fixed tabs.",
                "title": "Image of fixed tabs.",
                "src": "/static/3d174d55dfaaf14124a9fbefb2dd1f65/50383/component-variation-fixed-tabs-mobile-ios.png",
                "srcSet": ["/static/3d174d55dfaaf14124a9fbefb2dd1f65/1efb2/component-variation-fixed-tabs-mobile-ios.png 370w", "/static/3d174d55dfaaf14124a9fbefb2dd1f65/50383/component-variation-fixed-tabs-mobile-ios.png 740w", "/static/3d174d55dfaaf14124a9fbefb2dd1f65/536c7/component-variation-fixed-tabs-mobile-ios.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "scrollable"
        }}>{`Scrollable`}</h3>
        <p>{`If the page requires more tabs than can fit horizontally, tabs should resize to fit their label
width. Additional tabs should overflow off the right screen edge (indicated by a fade), and can be
accessed by swiping on the Tab Container.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/130cc6da246bf8de20cdf82c33e892cc/536c7/component-variation-overflow-tabs-mobile-ios.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of scrollable tabs, overflowing to the right",
                "title": "Image of scrollable tabs, overflowing to the right",
                "src": "/static/130cc6da246bf8de20cdf82c33e892cc/50383/component-variation-overflow-tabs-mobile-ios.png",
                "srcSet": ["/static/130cc6da246bf8de20cdf82c33e892cc/1efb2/component-variation-overflow-tabs-mobile-ios.png 370w", "/static/130cc6da246bf8de20cdf82c33e892cc/50383/component-variation-overflow-tabs-mobile-ios.png 740w", "/static/130cc6da246bf8de20cdf82c33e892cc/536c7/component-variation-overflow-tabs-mobile-ios.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tabs-with-icons"
        }}>{`Tabs with Icons`}</h3>
        <p>{`Only use icons if they provide value in reinforcing the meaning of the label. Icons should always be
leading, they do not appear above, below, or trailing to the label. Don't mix and match usage of
icons within tab items - ensure that all tab items either do or do not have a system icon for
consistency.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8ee16ab88dca04fbbbf00f86771a2fda/536c7/component-variation-icons-tabs-mobile-ios.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of tabs with icons.",
                "title": "Image of tabs with icons.",
                "src": "/static/8ee16ab88dca04fbbbf00f86771a2fda/50383/component-variation-icons-tabs-mobile-ios.png",
                "srcSet": ["/static/8ee16ab88dca04fbbbf00f86771a2fda/1efb2/component-variation-icons-tabs-mobile-ios.png 370w", "/static/8ee16ab88dca04fbbbf00f86771a2fda/50383/component-variation-icons-tabs-mobile-ios.png 740w", "/static/8ee16ab88dca04fbbbf00f86771a2fda/536c7/component-variation-icons-tabs-mobile-ios.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "fixed-position"
        }}>{`Fixed Position`}</h3>
        <p>{`Tabs can be fixed to the top of a page, especially when content is scrollable. This helps keep the
other categories in context.`}</p>
        <h2 {...{
          "id": "interaction-states"
        }}>{`Interaction States`}</h2>
        <p>{`Interaction states are applied on the Tab Item. They can be pressed, selected, or disabled.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dfb333aa010d844466fab3af7300689e/536c7/component-states-tabs-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of Tab interaction states of Pressed, Selected, and Disabled.",
                "title": "Image of Tab interaction states of Pressed, Selected, and Disabled.",
                "src": "/static/dfb333aa010d844466fab3af7300689e/50383/component-states-tabs-mobile.png",
                "srcSet": ["/static/dfb333aa010d844466fab3af7300689e/1efb2/component-states-tabs-mobile.png 370w", "/static/dfb333aa010d844466fab3af7300689e/50383/component-states-tabs-mobile.png 740w", "/static/dfb333aa010d844466fab3af7300689e/536c7/component-states-tabs-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`There should only be one active tab at a time. All tabs can be disabled except for the active tab.`}</p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Only one tab should be active at a time. Tabs should be composed of multiple tab items and span
device width.`}</li>
          <li parentName="ul">{`Avoid navigating to new pages while a user is interacting with a Tab.`}</li>
          <li parentName="ul">{`Keep tabs sticky to the top of the screen when scrolling through a page.`}</li>
          <li parentName="ul">{`While Tabs can have up to 8 tabs on mobile and 16 on tablets, try to limit the number of
categories to reduce cognitive load.`}</li>
          <li parentName="ul">{`Don't truncate tab labels to make them fit horizontally. Instead, use overflow tabs.`}</li>
          <li parentName="ul">{`Avoid using tabs for tasks that require users to switch back and forth for comparison.`}</li>
          <li parentName="ul">{`For right to left languages, mirror the layout of tabs. Tabs are right-aligned and their order is
reversed.`}</li>
        </ul>
        <h3 {...{
          "id": "behaviors"
        }}>{`Behaviors`}</h3>
        <ul>
          <li parentName="ul">{`Tapping a tab item should change its state to ‘Active’ and change the content of the tab view.`}</li>
          <li parentName="ul">{`Swiping left or right within the Tab Panel should navigate to the left or right Tab Item. Avoid
placing swipeable elements within the Tab Panel to prevent accidental actions on the wrong
component.`}</li>
          <li parentName="ul">{`Swiping left or right on overflowing Tabs should scroll the Tab Container horizontally to display
overflowing tabs.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To group related information into smaller, more consumable groups.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If page content is already cohesive, a Tab may be unnecessary.`}</li>
          <li parentName="ul">{`If users need to compare groups of information. Navigating between tabs forces users to rely on
short term memory, which is unreliable.`}</li>
          <li parentName="ul">{`When another tab is already being used on the page. Multiple tabs in the same page may indicate a
hefty UI - consider alternative ways to cut down on content.`}</li>
          <li parentName="ul">{`As a form of global navigation.`}</li>
          <li parentName="ul">{`To show logical progression in a flow.`}</li>
          <li parentName="ul">{`Don’t use tabs to toggle a view of the same content. Instead, consider using a Segmented Control.`}</li>
          <li parentName="ul">{`Don’t use tabs as a form of pagination.`}</li>
        </ul>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "tabs-bar"
        }}>{`Tabs Bar`}</h3>
        <h4 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h4>
        <p>{`This view is used in conjunction with `}<inlineCode parentName="p">{`TabsPager`}</inlineCode>{` to display the content associated with each tab.`}</p>
        <h4 {...{
          "id": "methods"
        }}>{`Methods`}</h4>
        <p>{`This component is typically created with`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    featureData: FeatureMetricsData,
    tabbedContentModels: [TabbedContentModel],
    selectedTabIndex: Binding<Int>,
    showDivider: Bool = false,
    onTabChanged: ((Int) -> Void)? = nil
)
`}</code></pre>
        <p>{`but there is an alternative that may fit some use cases during development.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    featureData: FeatureMetricsData,
    tabConfigs: [some TabConfig],
    selectedTabIndex: Binding<Int>,
    showDivider: Bool = false,
    onTabChanged: ((Int) -> Void)? = nil
)
`}</code></pre>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`featureData`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The feature name/context and the screen ID in which the component appears.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`tabbedContentModels`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array containing tab configurations and associated views. Design guidelines no not allow mixing `}<inlineCode parentName="td">{`TextTabConfig`}</inlineCode>{` with `}<inlineCode parentName="td">{`LabelTabConfig`}</inlineCode>{` but this is not enforced in this `}<inlineCode parentName="td">{`init`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`tabConfigs`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array of tab configurations that contain text, icon, and disabled information.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`selectedTabIndex`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A binding managed by the containing view and shared with `}<inlineCode parentName="td">{`TabsBar`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`showDivider`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Optionally add a thin divider below the tabs.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onTabChanged`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`An optional callback for when the tab changes, either by tapping the tab or swiping the pager.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "tabs-pager"
        }}>{`Tabs Pager`}</h3>
        <h4 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h4>
        <p>{`This view is used in conjunction with `}<inlineCode parentName="p">{`TabsBar`}</inlineCode>{` to display the content associated with each tab.`}</p>
        <h4 {...{
          "id": "methods-1"
        }}>{`Methods`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public init(
    tabbedContentModels: [TabbedContentModel],
    selectedTabIndex: Binding<Int>
)
`}</code></pre>
        <h4 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`tabbedContentModels`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Array containing tab configurations and associated views. Design guidelines no not allow mixing `}<inlineCode parentName="td">{`TextTabConfig`}</inlineCode>{` with `}<inlineCode parentName="td">{`LabelTabConfig`}</inlineCode>{` but this is not enforced in this `}<inlineCode parentName="td">{`init`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`selectedTabIndex`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A binding managed by the containing view and shared with `}<inlineCode parentName="td">{`TabsBar`}</inlineCode>{`.`}</td>
            </tr>
          </tbody>
        </table>
        <h3 {...{
          "id": "tab-configuration"
        }}>{`Tab Configuration`}</h3>
        <p>{`The content and appearance of tabs relies on the `}<inlineCode parentName="p">{`TabConfig`}</inlineCode>{` protocol.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public protocol TabConfig {
    var displayText: String { get }
    var icon: Image? { get }
    var isDisabled: Bool { get }
}
`}</code></pre>
        <p>{`Two structs conform to the `}<inlineCode parentName="p">{`TabConfig`}</inlineCode>{` protocol, one with only text`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct TextTabConfig: TabConfig {
    public let displayText: String
    public let isDisabled: Bool

    public let icon: Image? = nil

    public init(displayText: String, isDisabled: Bool = false) {
        self.displayText = displayText
        self.isDisabled = isDisabled
    }
}
`}</code></pre>
        <p>{`and another with a "label" (text and icon).`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct LabelTabConfig: TabConfig {
    public let displayText: String
    public let icon: Image?
    public let isDisabled: Bool

    public init(displayText: String, icon: Image, isDisabled: Bool = false) {
        self.displayText = displayText
        self.icon = icon
        self.isDisabled = isDisabled
    }
}
`}</code></pre>
        <h3 {...{
          "id": "tabbed-content-model"
        }}>{`Tabbed Content Model`}</h3>
        <p>{`Using both `}<inlineCode parentName="p">{`TabsBar`}</inlineCode>{` and `}<inlineCode parentName="p">{`TabsPager`}</inlineCode>{` requires using an array of `}<inlineCode parentName="p">{`TabbedContentModel`}</inlineCode>{`.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public struct TabbedContentModel {
    public let tabConfig: TabConfig
    public let pageView: AnyView

    public init<Content: View>(tabConfig: TabConfig, pageView: Content) {
        self.tabConfig = tabConfig
        self.pageView = AnyView(pageView)
    }

    public init<Content: View>(tabConfig: TabConfig, pageView: () -> Content) {
        self.tabConfig = tabConfig
        self.pageView = AnyView(pageView())
    }
}
`}</code></pre>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`When writing tab labels, be concise and avoid using multiple words when possible. Labels should
clearly describe the contents of the tab to the user.`}</li>
          <li parentName="ul">{`The page or page section title (if applicable) should make clear sense when placed after the tab
name. Try to imagine the page title is a noun after the tab label. For example: If the page is
titled “Fruit”, and the tabs are labeled “ripe” and “rotten”. You could read it in your head as
“ripe fruit” or “rotten fruit”.`}</li>
          <li parentName="ul">{`Refer to the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/tabs"
            }}>{`Tabs`}</a>{` section of the Content Style Guide when writing tab
labels.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7739%3A7687&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/a94cc0b60865d13855bc3d4d882a80bc/536c7/component-anatomy-tabs-mobile-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Tab component.",
                "title": "The basic elements of the Tab component.",
                "src": "/static/a94cc0b60865d13855bc3d4d882a80bc/50383/component-anatomy-tabs-mobile-android.png",
                "srcSet": ["/static/a94cc0b60865d13855bc3d4d882a80bc/1efb2/component-anatomy-tabs-mobile-android.png 370w", "/static/a94cc0b60865d13855bc3d4d882a80bc/50383/component-anatomy-tabs-mobile-android.png 740w", "/static/a94cc0b60865d13855bc3d4d882a80bc/536c7/component-anatomy-tabs-mobile-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Tab Container`}</strong>{`: Contains all tab items.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item (Active)`}</strong>{`: Tab item whose content is currently displayed.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item (Inactive)`}</strong>{`: All other unselected, available tabs.`}</li>
          <li parentName="ol"><strong parentName="li">{`Active Chip`}</strong>{`: Highlights the active tab.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item Label`}</strong>{`: Describes the content contained.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Item Icon (Optional)`}</strong>{`: Leading icon used to reinforce association with the label.`}</li>
          <li parentName="ol"><strong parentName="li">{`Tab Panel`}</strong>{`: The content of the tab item.`}</li>
          <li parentName="ol"><strong parentName="li">{`Divider (Optional)`}</strong>{`: Spans the tab container to separate it from its content.`}</li>
        </ol>
        <h2 {...{
          "id": "variations-2"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "fixed-width-1"
        }}>{`Fixed Width`}</h3>
        <p>{`All tab items have evenly distributed widths in the tab container. These tabs are not scrollable.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/29609a40d6dad7e83d3da9492ae4dfa3/536c7/component-variation-fixed-tabs-mobile-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of fixed tabs.",
                "title": "Image of fixed tabs.",
                "src": "/static/29609a40d6dad7e83d3da9492ae4dfa3/50383/component-variation-fixed-tabs-mobile-android.png",
                "srcSet": ["/static/29609a40d6dad7e83d3da9492ae4dfa3/1efb2/component-variation-fixed-tabs-mobile-android.png 370w", "/static/29609a40d6dad7e83d3da9492ae4dfa3/50383/component-variation-fixed-tabs-mobile-android.png 740w", "/static/29609a40d6dad7e83d3da9492ae4dfa3/536c7/component-variation-fixed-tabs-mobile-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "scrollable-1"
        }}>{`Scrollable`}</h3>
        <p>{`If the page requires more tabs than can fit horizontally, tabs should resize to fit their label
width. Additional tabs should overflow off the right screen edge (indicated by a fade), and can be
accessed by swiping on the Tab Container.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6a41ad7b5b007dde1c5d849f56014b71/536c7/component-variation-overflow-tabs-mobile-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of scrollable tabs, overflowing to the right",
                "title": "Image of scrollable tabs, overflowing to the right",
                "src": "/static/6a41ad7b5b007dde1c5d849f56014b71/50383/component-variation-overflow-tabs-mobile-android.png",
                "srcSet": ["/static/6a41ad7b5b007dde1c5d849f56014b71/1efb2/component-variation-overflow-tabs-mobile-android.png 370w", "/static/6a41ad7b5b007dde1c5d849f56014b71/50383/component-variation-overflow-tabs-mobile-android.png 740w", "/static/6a41ad7b5b007dde1c5d849f56014b71/536c7/component-variation-overflow-tabs-mobile-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tabs-with-icons-1"
        }}>{`Tabs with Icons`}</h3>
        <p>{`Only use icons if they provide value in reinforcing the meaning of the label. Icons should always be
leading, they do not appear above, below, or trailing to the label. Don't mix and match usage of
icons within tab items - ensure that all tab items either do or do not have a system icon for
consistency.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e8ea998602f5acf541976c25add5fb77/536c7/component-variation-icons-tabs-mobile-android.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image of tabs with icons.",
                "title": "Image of tabs with icons.",
                "src": "/static/e8ea998602f5acf541976c25add5fb77/50383/component-variation-icons-tabs-mobile-android.png",
                "srcSet": ["/static/e8ea998602f5acf541976c25add5fb77/1efb2/component-variation-icons-tabs-mobile-android.png 370w", "/static/e8ea998602f5acf541976c25add5fb77/50383/component-variation-icons-tabs-mobile-android.png 740w", "/static/e8ea998602f5acf541976c25add5fb77/536c7/component-variation-icons-tabs-mobile-android.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "fixed-position-1"
        }}>{`Fixed Position`}</h3>
        <p>{`Tabs can be fixed to the top of a page, especially when content is scrollable. This helps users keep
the context of other categories in the tab.`}</p>
        <h2 {...{
          "id": "interaction-states-1"
        }}>{`Interaction States`}</h2>
        <p>{`Interaction states are applied on the Tab Item. They can be pressed, selected, or disabled.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dfb333aa010d844466fab3af7300689e/536c7/component-states-tabs-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Tab pressed, selected, and disabled interaction states.",
                "title": "Tab pressed, selected, and disabled interaction states.",
                "src": "/static/dfb333aa010d844466fab3af7300689e/50383/component-states-tabs-mobile.png",
                "srcSet": ["/static/dfb333aa010d844466fab3af7300689e/1efb2/component-states-tabs-mobile.png 370w", "/static/dfb333aa010d844466fab3af7300689e/50383/component-states-tabs-mobile.png 740w", "/static/dfb333aa010d844466fab3af7300689e/536c7/component-states-tabs-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`There should only be one active tab at a time. All tabs can be disabled except for the active tab.`}</p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`Only one tab should be active at a time. Tabs should be composed of multiple tab items and span
device width.`}</li>
          <li parentName="ul">{`Avoid navigating to new pages while a user is interacting with a Tab.`}</li>
          <li parentName="ul">{`Keep tabs sticky to the top of the screen when scrolling through a page.`}</li>
          <li parentName="ul">{`While Tabs can have up to 8 tabs on mobile and 16 on tablets, try to limit the number of
categories to reduce cognitive load.`}</li>
          <li parentName="ul">{`Don't truncate tab labels to make them fit horizontally. Instead, use overflow tabs.`}</li>
          <li parentName="ul">{`Avoid using tabs for tasks that require users to switch back and forth for comparison.`}</li>
          <li parentName="ul">{`For right to left languages, mirror the layout of tabs. Tabs are right-aligned and their order is
reversed.`}</li>
        </ul>
        <h3 {...{
          "id": "behaviors-1"
        }}>{`Behaviors`}</h3>
        <ul>
          <li parentName="ul">{`Tapping a tab item should change its state to ‘Active’ and change the content of the tab view.`}</li>
          <li parentName="ul">{`Swiping left or right within the Tab Panel should navigate to the left or right Tab Item. Avoid
placing swipeable elements within the Tab Panel to prevent accidental actions on the wrong
component.`}</li>
          <li parentName="ul">{`Swiping left or right on overflowing Tabs should scroll the Tab Container horizontally to display
overflowing tabs.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To group related information into smaller, more consumable groups.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If page content is already cohesive, a Tab may be unnecessary.`}</li>
          <li parentName="ul">{`If users need to compare groups of information. Navigating between tabs forces users to rely on
short term memory, which is unreliable.`}</li>
          <li parentName="ul">{`When another tab is already being used on the page. Multiple tabs in the same page may indicate a
hefty UI - consider alternative ways to cut down on content.`}</li>
          <li parentName="ul">{`As a form of global navigation.`}</li>
          <li parentName="ul">{`To show logical progression in a flow.`}</li>
          <li parentName="ul">{`Don’t use tabs to toggle a view of the same content. Instead, consider using a Segmented Control.`}</li>
          <li parentName="ul">{`Don’t use tabs as a form of pagination.`}</li>
        </ul>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-2"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun [TabsUiComponent](
  components: [List](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin.collections/-list/index.html)&lt;[Pair](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/-pair/index.html)&lt;[TabComponent],
  @Composable() -&gt; [Unit](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/-unit/index.html)&gt;&gt;,
  tabRowType: [TabRowType],
  hasDivider: [Boolean](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/-boolean/index.html)
)
`}</code></pre>
        <h3 {...{
          "id": "parameters-2"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`components`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A list of pairs. The Tab is the type of tab that is displayed and the content is what is displayed when the tab is selected`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`tabRowType`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Tab Row types include: FIXED and SCROLLABLE`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`hasDivider`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Controls whether a divider is shown below the tabs`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`When writing tab labels, be concise and avoid using multiple words when possible. Labels should
clearly describe the contents of the tab to the user.`}</li>
          <li parentName="ul">{`The page or page section title (if applicable) should make clear sense when placed after the tab
name. Try to imagine the page title is a noun after the tab label. For example: If the page is
titled “Fruit”, and the tabs are labeled “ripe” and “rotten”. You could read it in your head as
“ripe fruit” or “rotten fruit”.`}</li>
          <li parentName="ul">{`Refer to the `}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/tabs"
            }}>{`Tabs`}</a>{` section of the Content Style Guide when writing tab
labels.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      